import Swal from "sweetalert2";
import axios from "../Utils/Axios";

export const handleDelete = (url, id, token, callback, setLoading) => {
    console.log('====================================');
    console.log(url);
    console.log('====================================');
    Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
    }).then((result) => {
        if (result.isConfirmed) {
            setLoading(true);
            let formData = new FormData();
            formData.append("id", id);

            axios(url, {
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        Swal.fire("Deleted!", response.data.message, "success");
                        if (typeof callback === "function") callback(); // Refresh data
                    } else {
                        Swal.fire("Error!", response.data.message, "error");
                    }
                })
                .catch(({ response }) => {
                    setLoading(false);
                    Swal.fire("Error!", response?.data?.message || "Something went wrong.", "error");
                });
        }
    });
};