import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { useAlert } from "react-alert";
import { ErrorHandler } from "../../ui/ErrorHandler";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from "@mui/material";
import EditFaq from "./EditFaq";
import { handleDelete } from "../../Helpers/handleDelete";
import './Faq.css'


function Faq({ page, pageId }) {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getFaq();
  }, [pageId]);

  const getFaq = () => {
    let formData = new FormData();
    formData.append("page", page);
    formData.append("page_id", pageId);
    axios(`admin/faqs`, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setFaqs(response.data.data);
        } else {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message)
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        ErrorHandler(alert, response.status, response.data.message)
      });
  };

  const faqAdd = (event) => {
    event.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("question", question);
    formData.append("answer", answer);
    formData.append("page", page);
    formData.append("page_id", pageId);
    axios("admin/faq_save", {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message);
          setAnswer('');
          setQuestion('');
          getFaq();
        } else {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message)
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        ErrorHandler(alert, response.status, response.data.message)
      });
  };

  const faqUpdate = (faq) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("question", faq.question);
    formData.append("answer", faq.answer);
    formData.append("id", faq.id);

    axios("admin/faq_update", {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message);
          setAnswer('');
          setQuestion('');
          getFaq();
        } else {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message)
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        ErrorHandler(alert, response.status, response.data.message)
      });
  };

  const faqDelete = (id) => {
    const token = JSON.parse(localStorage.getItem("data")); // Get token from storage
    handleDelete("admin/faq_delete", id, token, getFaq, setLoading);
  };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "rgba(0, 0, 0, 0.8)",
          },
        }),
      }}
      spinner={<RingLoader loading={loading} color="#36d7b7" />}
      text="..."
    >
      <div className="row bg-smoke">
        <h3 className="pb-2">FAQ Section</h3>
        <div className="col-md-12  FAQ">
          <div className="row align-items-center p-3">
            <div class="col-md-5 form-group mb-0">
              <textarea placeholder="Enter Question" id="exampleInputQuestion" class="form-control" onChange={(e) => setQuestion(e.target.value)} value={question}></textarea>
            </div>
            <div class="col-md-5 form-group mb-0">
              <textarea placeholder="Enter Answer" id="exampleInputAnswer" class="form-control" onChange={(e) => setAnswer(e.target.value)} value={answer}></textarea>
            </div>
            <button type="button" onClick={faqAdd} class=" col-md-2 btn btn-primary">Add Faq</button>
          </div>
        </div>
        <div className="col-md-12">
          {faqs.length > 0 ? faqs.map((value, index) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box p={1} color="primary" >
                    <Typography
                      variant="h6"
                      color="primary"
                      gutterBottom
                    >
                      {value.question}

                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <EditFaq faq={value} faqUpdate={faqUpdate} faqDelete={faqDelete}></EditFaq>
                </AccordionDetails>
              </Accordion>
            )
          }) : ''}
        </div>
      </div>
    </LoadingOverlay>
  )
}

export default Faq