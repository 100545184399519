import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { ErrorHandler } from "../../ui/ErrorHandler";
import { handleDelete } from "../../Helpers/handleDelete";
import "./BlogCategory.css";

function Categories() {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [per_page, setPer_page] = useState(10);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [keyword, setSearch] = useState();
    const alert = useAlert();

    useEffect(() => {
        getCategories();
    }, [page, per_page, keyword]);

    const getCategories = () => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("page", page);
        formdata.append("per_page", per_page);
        const qs = require('qs');
        let filters = {
            $or: [
                { name: { $contains: keyword } },

            ]
        };
        const query = qs.stringify({ filters }, { encode: false });
        axios.post(`admin/blog_categories?${query}`, formdata, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCategories(response.data.data.data);
                    setTotal(response.data.data.last_page);
                    setLoading(false);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const deleteCategory = (id) => {
        const token = JSON.parse(localStorage.getItem("data")); // Get token from storage
        handleDelete("admin/blog_cat_delete", id, token, getCategories, setLoading);
    };

    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            to="/categories"
                        >
                            Blog Categories
                        </Link>
                    </Breadcrumbs>
                </div>
                <div class="mb-4">
                    <div class="card-body">
                        <h1 class="h2 mb-2 text-gray-800">Blog Categories</h1>
                        <hr></hr>
                        <div className="col-md-12 text-right">
                            <Link to="./add-blog_category"><button className="btn btn-primary"> <i class="fi fi-rr-rectangle-history-circle-plus"></i> Add Blog Categories</button></Link>
                        </div>
                        <div class="container-fluid">
                            <div className="mt-2">
                                <div className="row mb-3">

                                    <div className="col-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search.... "
                                            onChange={(e) => setSearch(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Name</th>
                                                    <th>Created At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categories.length > 0 ? categories.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.name}</td>
                                                            <td>{value.created_at}</td>
                                                            <td className="space-evenly">
                                                                <Link to={'/edit-blog_category/' + value.id}><button className="btn btn-sm btn-dark" title="Edit"><i className="fi fi-rr-file-edit"></i></button></Link>
                                                                <button className="btn btn-sm btn-danger" title="Delete" onClick={() => deleteCategory(value.id)}><i className="fi fi-rr-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={5} className="text-center"> No Categories</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>
                                    <div>
                                        <Box p={2} display="flex" justifyContent="center">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    color="secondary"
                                                    count={total}
                                                    page={page}
                                                    onChange={(event, value) => {
                                                        setPage(value);
                                                    }}
                                                />
                                            </Stack>
                                            <Stack spacing={2}>
                                                <Select
                                                    value={per_page}
                                                    onChange={(e) => setPer_page(e.target.value)}
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Categories;
